import { Capacitor } from '@capacitor/core'

const additionalConditions = () => {
  if (process.dev) {
    return (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    )
  }
  return false
}

export const isMobile = () => {
  return Capacitor.isNativePlatform() || additionalConditions()
}
